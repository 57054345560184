@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&family=M+PLUS+1+Code:wght@100..700&display=swap');

@layer base {
    body{
        @apply font-[Inconsolata];
    }
    li{
         @apply px-4;
         @apply cursor-pointer;
    }
}